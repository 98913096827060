import { create } from 'zustand'


interface useRedRibbonEntity {
    isOpen: boolean;
    isUpdate: boolean;
    setClose: () => void;
    setOpen : () => void;
    setToggleUpdate:()=>void
}

export const useRedRibbon = create<useRedRibbonEntity>((set) => ({
    isOpen: true,
    isUpdate:false,
    setToggleUpdate:()=> set((state=> ({isUpdate:!state.isUpdate}))),
    setClose:()=> set((state)=>({isOpen:false})),
    setOpen:()=> set((state)=>({isOpen:true}))
    
}))
