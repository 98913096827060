export const BaseEndpoints = {
  systemTypeSetting: "system-types-settings",
  subSystemSetting: "sub-system-settings",
  systemSetting: "system-settings",
  modelSetting: "model-settings",
  glassFinishSettings: "glass-finish-settings",
  glassSubCategoriesSettings: "glass-sub-categories-settings",
  handlesSettings: "handles-settings",
  frameColorsSettings: "frame-colors-settings",
  gridSettings: "grid",
  lockSettings: "lock-settings",
  glassCategoriesSettings: "glass-categories-settings",
  order: "order",
  payment: "payment",
  refundPayment: "refund-payment",
  paymentHistory: "payment-history",
  user: "user",
  office: "office",
  role: "role",
  download: "download",
  source: "sources",
  generalSettings: "general-settings",
  desginSettings: "design-settings",
  glassVariantSettings: "glass-variant-settings",
  glGlassVariantSettings: "gl-glass-variant-settings",
  glassfinishesSettings: "glass-finish-settings",
  glGlassfinishesSettings: "gl-glass-finish-settings",
  glGlassSubCategoriesSettings: "gl-glass-sub-categories-settings",
  glGlassCategoriesSettings: "gl-glass-categories-settings",
  hingeSetting: "hinge-settings",
  glassOnlyFinishSettings: "glass-only-finish-settings",
  glassOrder: "glass-order",
  analytics: "analytics",
  procore: "procore",
  modules: "modules",
  priceVersion: "price-version",
  states: "states",
  cities: "cities",
  architectDetails: "architect-details",
  orderPercentage: "order-percentage",
  dashboard: "dashboard",
  campaign:'campaign',
  waltzOder: "waltz-order",
  panelOnly: "panel-only",
  redFlag: "red-flag",
  RedRibbon: "red-ribbon",
  quotationRequestUsers: "quotation-users-request",
  website: "website"
};
