
function UnderMaintenance() {
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper ps-0">
                <div className="content-wrapper d-flex align-items-center auth p-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-6 col-md-6 col-sm-12 p-0 login-img">
                            <div className="loginImg"></div>
                        </div>

                        <div className="col-lg-4 mx-auto d-flex align-items-center">
                            <div className="auth-form-light text-left py-5 px-4 w-100">
                                <div className="brand-logo">
                                    <img src="assets/images/logo.png" alt="logo" />
                                </div>

                                <h4 className='mb-3 lh-base fw-bold' style={{ textAlign: 'justify' }}>Apologies for the inconvenience. We are currently undergoing maintenance to improve our services. Please try again later. Thank you for your understanding.</h4>

                                <button className="btn btn-block btn-primary font-weight-medium auth-form-btn mt-2"
                                    type='button' onClick={() => window.location.href = '/'}>
                                    Home
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnderMaintenance
