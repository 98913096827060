import React, { useEffect, useState, useRef } from "react";
import { PaginateReqEntity, PaginateRespEntity } from "../../interface";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { APIResponseEntity } from "../../interface/api-responce";
import {
  APIGetQuotationRequestUsers,
  APIQuotationCSVDownload,
} from "../../service/api-service";
import { searchInputPlaceholder, toastOptObj } from "../../utils/constants";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import Loader from "../../components/loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { render30CharcterAfterDot } from "../../service/shared.service";
import PrimePaginator from "../../components/prime-paginator";
import {
  IsNullOrEmptyArray,
  IsStringNullEmptyOrUndefined,
} from "../../utils/null-check";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import {
  QuotationRequestUsersDetailsResp,
  QuotationSummaryDetailsResp,
} from "../../interface/quotation-details";
import { downloadCsvFile } from "../../service/shared.service";
import { Calendar } from "primereact/calendar";
import { FilterOrderDatesEntity } from "../../interface/order";

declare var $: any;

function QuotationRequestUsers() {
  const [filterDates, setFilterDates] = useState<FilterOrderDatesEntity>({
    fromDate: null,
    toDate: null,
  });
  const [filterReq, setFilterReq] = useState<PaginateReqEntity>({
    pageSize: 10,
    pageNumber: 0,
    searchText: "",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [checkQuotationDialog, setCheckQuotationDialog] = useState(false);

  const [quotationRequestUsersDetails, setQuotationRequestUsersDetails] =
    useState<QuotationRequestUsersDetailsResp[] | []>([]);

  const [quotationSummaryDetails, setQuotationSummaryDetails] = useState<
    QuotationSummaryDetailsResp[] | []
  >([]);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("asce");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const fromToDateMinDate = new Date("2000-01-01");
  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await fetchQuotationRequestUsers();
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterReq]);

  useEffect(() => {
    if (filterDates.fromDate) {
      const quotationFromDate: string = moment(filterDates.fromDate).format(
        "YYYY-MM-DD"
      );
      setFilterReq((prev) => ({
        ...prev,
        quotationFromDate,
        pageNumber: 0,
      }));
    }
  }, [filterDates.fromDate]);

  useEffect(() => {
    if (filterDates.toDate) {
      const quotationToDate: string = moment(filterDates.toDate).format(
        "YYYY-MM-DD"
      );
      setFilterReq((prev) => ({
        ...prev,
        quotationToDate,
        pageNumber: 0,
      }));
    }
  }, [filterDates.toDate]);

  useEffect(() => {
    $("#id_of_searchbox").keyup((event: any) => {
      const searchText: string = IsStringNullEmptyOrUndefined(
        event.target.value
      )
        ? ""
        : event.target.value;

      if (
        event.keyCode === 13 &&
        (IsStringNullEmptyOrUndefined(searchText) ||
          (!IsStringNullEmptyOrUndefined(searchText) && searchText.length >= 3))
      ) {
        setFilterReq({ ...filterReq, searchText, pageNumber: 0 });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQuotationRequestUsers = async (): Promise<void> => {
    setIsLoading(true);
    const resp: AxiosResponse<APIResponseEntity> =
      await APIGetQuotationRequestUsers(filterReq);
    setIsLoading(false);
    console.log("filterReq:- ", filterReq);
    setQuotationRequestUsersDetails([]);
    setTotalPageCount(0);

    if (resp) {
      if (resp && resp.data && resp.status === 200) {
        const axiosResp: APIResponseEntity = resp.data;

        if (
          axiosResp &&
          axiosResp.data &&
          axiosResp.status &&
          axiosResp.statusCode === 200
        ) {
          const paginateResp: PaginateRespEntity = axiosResp.data;
          setQuotationRequestUsersDetails(paginateResp.data);
          setTotalPageCount(paginateResp.totalCount);
        }
      } else {
        toast.error(resp.data.message, { ...toastOptObj });
      }
    }
  };

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    setFilterReq({
      ...filterReq,
      pageSize: event.rows,
      pageNumber: event.page,
    });
  };

  const columnActionBody = (row: any) => {
    return (
      <>
        {!IsNullOrEmptyArray(row.requestedQuotations) && (
          <ul className="tableIcon cursor-pointer d-flex">
            <li
              data-toggle="tooltip"
              title="Check Quotations"
              onClick={() => {
                setCheckQuotationDialog(true);
                setQuotationSummaryDetails(row.requestedQuotations);
              }}
            >
              <img src="assets/images/eye.svg" alt="View" />
            </li>
          </ul>
        )}
      </>
    );
  };
  const calendarRef = useRef<Calendar>(null);
  const quotationPDFDownload = async (): Promise<void> => {
    setIsLoading(true);
    const resp: AxiosResponse<APIResponseEntity> =
      // await APIFetchOrdersCsvDetails();
      await APIQuotationCSVDownload(filterReq);
    setIsLoading(false);
    if (resp) {
      if (resp && resp.data && resp.status === 200) {
        const axiosResp: APIResponseEntity = resp.data;

        if (
          axiosResp &&
          axiosResp.data &&
          axiosResp.status &&
          axiosResp.statusCode === 200
        ) {
          const excelUrl = axiosResp.data.xlsxUrl;
          if (excelUrl && !IsStringNullEmptyOrUndefined(excelUrl)) {
            const fileName: string = excelUrl.split("/").pop();
            if (fileName && !IsStringNullEmptyOrUndefined(fileName)) {
              await downloadCsvFile(excelUrl, fileName);
            }
          }
        } else {
          toast.error(resp.data.message, { ...toastOptObj });
        }
      }
    }
  };

  const filterTemplateDate = () => {
    return (
      <div className="d-flex gap-1">
        <Calendar
          value={filterDates.fromDate}
          dateFormat="dd/mm/yy"
          placeholder="From"
          minDate={fromToDateMinDate}
          maxDate={new Date()}
          onChange={(e) =>
            setFilterDates({ ...filterDates, fromDate: e.target.value })
          }
          ref={calendarRef}
        />

        <Calendar
          value={filterDates.toDate}
          placeholder="To"
          minDate={
            filterDates.fromDate ? filterDates.fromDate : fromToDateMinDate
          }
          maxDate={new Date()}
          dateFormat="dd/mm/yy"
          onChange={(e) =>
            setFilterDates({ ...filterDates, toDate: e.target.value })
          }
          ref={calendarRef}
        />
      </div>
    );
  };
  return (
    <>
      <Loader isLoading={isLoading} />

      <div className="content-wrapper">
        <div className="col-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-rounded">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div className="col-lg-8 col-md-8 col-12">
                        <h4 className="card-title">Get Quote Details</h4>
                        <div className="input-group search-grp">
                          <img
                            src="assets/images/search.svg"
                            alt="search"
                            className="input-group-text searchIptGrpTxt"
                          />
                          <input
                            type="text"
                            className="form-control form-control-lg searchInput"
                            placeholder={searchInputPlaceholder}
                            value={searchKeyword}
                            id="id_of_searchbox"
                            onChange={(e) => setSearchKeyword(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 text-md-end text-lg-end mt-md-0">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => quotationPDFDownload()}
                      >
                        Export
                      </button>
                    </div>
                  </div>
                  <div className=" row col-md-12">
                    <DataTable
                      value={quotationRequestUsersDetails}
                      filterDisplay="row"
                    >
                      <Column
                        field="fullName"
                        showFilterMenu={false}
                        header="Full Name"
                        body={(body: QuotationRequestUsersDetailsResp) => {
                          return render30CharcterAfterDot(body.fullName);
                        }}
                      ></Column>

                      <Column
                        field="phoneNo"
                        header="Mobile No"
                        showFilterMenu={false}
                        body={(body: QuotationRequestUsersDetailsResp) => {
                          return render30CharcterAfterDot(body.phoneNo);
                        }}
                      ></Column>
                      <Column
                        field="cityName"
                        header="City Name"
                        showFilterMenu={false}
                        body={(body: QuotationRequestUsersDetailsResp) => {
                          return render30CharcterAfterDot(body.cityName);
                        }}
                      ></Column>
                      <Column
                        field="stateName"
                        header="State Name"
                        showFilterMenu={false}
                        body={(body: QuotationRequestUsersDetailsResp) => {
                          return render30CharcterAfterDot(body.stateName);
                        }}
                      ></Column>
                      <Column
                        field="OTPCreatedAt"
                        header="Date"
                        showFilterMenu={false}
                        filter
                        frozen
                        filterElement={filterTemplateDate}
                        body={(body: QuotationRequestUsersDetailsResp) => {
                          return render30CharcterAfterDot(
                            moment(body.OTPCreatedAt).format("YYYY-MM-DD")
                          );
                        }}

                        // onFilterOperatorChange={console.log("Changed")}
                      ></Column>
                      <Column header="" body={columnActionBody} />
                    </DataTable>
                  </div>

                  {!IsNullOrEmptyArray(quotationRequestUsersDetails) && (
                    <PrimePaginator
                      onPageChange={onPageChange}
                      pageNumber={filterReq.pageNumber}
                      pageSize={filterReq.pageSize}
                      totalRecords={totalPageCount}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header="Quotation Summary"
        visible={checkQuotationDialog}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!checkQuotationDialog) return;
          setCheckQuotationDialog(false);
        }}
        draggable={false}
      >
        <DataTable value={quotationSummaryDetails}>
          <Column
            field="systemName"
            header="Systen Name"
            body={(body: QuotationSummaryDetailsResp) => {
              return render30CharcterAfterDot(body.systemName);
            }}
          ></Column>
          <Column
            field="width"
            header="Width"
            body={(body: QuotationSummaryDetailsResp) => {
              return render30CharcterAfterDot(body.width);
            }}
          ></Column>
          <Column
            field="height"
            header="Height"
            body={(body: QuotationSummaryDetailsResp) => {
              return render30CharcterAfterDot(body.height);
            }}
          ></Column>
        </DataTable>
      </Dialog>
    </>
  );
}

export default QuotationRequestUsers;
